
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartEquimentBathroom extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyEquimentBathroom;

    localValue: PropertyEquimentBathroom = {
        baths: null,
        showers: null,
        sinks: null,
        toilets: null,
        guest_toilets: null,
        double_sinks: null,
    };

    resetValues() {
        this.handleInput();
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyEquimentBathroom) {
        this.localValue = newValue;
    }
}
